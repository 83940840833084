<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="row">
        <div class="w-8/12">
          <div v-html="announcement.header" class="text-2xl font-bold"></div><br>
          <span>สร้างประกาศเมื่อวันที่ {{ announcement.created }} น.</span>
        </div>
        <div class="ml-auto sm:w-5/12 xl:w-4/12">
          <button v-if="role === 'STF'" v-on:click="notification()" class="noti-button mr-8">แจ้งเตือน</button>
          <router-link v-if="role === 'STF'" :to="'/announcement/' + this.$route.params.id + '/edit/'" class="mr-8">
            <button class="edit-button">แก้ไขประกาศ</button>
          </router-link>
          <button v-if="role === 'STF'" v-on:click="deleteAnnouncement(this.announcement)" class="delete-button">ลบประกาศ</button>
        </div>
      </div>

      <!-- Description -->
      <div v-html="announcement.description" class="mt-12" id="description"></div>

      <!-- File list -->
      <div v-if="has_file" class="my-4">
        <div v-for="file in announcement.file" :key="file.id" class="row">
          <button v-on:click="download(file)" class="view-button">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24" stroke="white"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/></svg>
          </button>
          <p class="my-auto ml-4">{{ file.name }}</p>
        </div>
      </div>
      <p v-if="role === 'STF'" class="text-error mt-20" style="margin-left: 0px">ประกาศข่าวสารจะไม่ทำการแจ้งเตือนโดยอัตโนมัติ หากเจ้าหน้าที่ต้องการทำการแจ้งเตือนนักศึกษาทุกรายในรายวิชาให้กดที่ปุ่ม "แจ้งเตือน"</p>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import moment from "moment"

export default {
  name: "AnnouncementView",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      announcement: {
        header: null
      },
      has_file: false,
      role: null,
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    this.announcement = await this.$store.dispatch("getAnnouncementById", this.$route.params.id)
    this.has_file = this.announcement.file.length > 0 ? true : false
    this.announcement.created = moment(new Date(this.announcement.created)).locale('th').add(543, 'year').format('lll')
    this.announcement.noti_type = "ann"
    this.header = this.announcement.header.replace(/<\/?[^>]+(>|$)/g, "")
    document.title = this.header + " | Carbon"
    this.loading = false
  },
  methods: {
    async deleteAnnouncement(ann) {
      if (confirm("ต้องการลบประกาศ " + this.header +  " หรือไม่") == true) {
        const success = await this.$store.dispatch("deleteAnnouncementById", ann.id)
        if(success) {
          alert("ลบประกาศเสร็จสิ้น")
          this.$router.push("/")
        }
      }
    },
    async download(file) {
      file.type = "document"
      await this.$store.dispatch("downloadDocumentFile", file)
    },
    async notification() {
      if (confirm("ต้องการแจ้งเตือนประกาศ " + this.header +  " หรือไม่") == true) {
        await this.$store.dispatch("notification", this.announcement)
        alert("แจ้งเตือนเสร็จสิ้น")
      }
    }
  },
}
</script>